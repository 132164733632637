





































































import { Component, Vue } from 'vue-property-decorator'
import monitorService, { MonitorData, SocketClient } from '@/services/monitorService'
import utility from '@/common/utility'

@Component
export default class ClientStatus extends Vue {
  Data: MonitorData = null
  UpdateInterval: number
  ShowDetails = false
  ClickTimes = 0

  async mounted () {
    await this.loadData()
  }

  showDebug () {
    this.ClickTimes++
    if (this.ClickTimes > 4) {
      this.ShowDetails = true
    }
  }

  async loadData () {
    if (this.UpdateInterval) {
      clearInterval(this.UpdateInterval)
    }
    const res = await monitorService.getStatusAsync()
    for (const item of res.Result.Conns) {
      utility.freezeArrayAndItems(item.TaskPipes)
      utility.freezeArrayAndItems(item.UnSubscribedSymbols)
    }
    this.Data = Object.freeze(res.Result)
    this.UpdateInterval = setTimeout(async () => {
      await this.loadData()
    }, 3000)
  }

  destroyed () {
    clearInterval(this.UpdateInterval)
  }
}
