import { ApiResult } from './data'
import serviceBase from '@/services/serviceBase'

export interface MonitorData {
  WebSockets: SocketClient[];
  Conns: Pipe[];
}

export interface Pipe {
  Name: string;
  IsConnected: boolean;
  GatewayConfig: Host;
  TaskPipes: TaskPipe[];
  LastCheckDate: string;
  UnSubscribedSymbols: string[];
}

export interface Host {
  ClientId: number;
  Host: string;
  Port: number;
}

export interface QueueEntry {
  Priority: number;
  Symbol: string;
  Status: string;
  Interval: number;
  ReadFlags: number;
  Sources: { UserName: string; SourceDesc: string; UN?: string }[];
}

export interface TaskPipe {
  Name: string;
  Limit: number;
  Waiting: QueueEntry[];
  Working: QueueEntry[];
}

export interface SocketClient {
  State: string;
  CurrentPage: string;
  IsAgent: boolean;
  AgentVer: string;
  Name: number;
  WsSocketId: string;
}

export default {
  async getStatusAsync (): Promise<ApiResult<MonitorData>> {
    return (await serviceBase.tryPostResult<MonitorData>('/Monitor/getStatus'))
  }
}
